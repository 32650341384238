
.error-msg {
  position: relative;
  top: 50%;
  left: 25%;

  details {
    width: 60%;
  }
}
