@import "common.scss";

$help-color: #ab8dcc;
$font: bold 16px firasans;

.help-page {
  position: relative;
  padding: 1em 1em 0.5em 1em;
  font: $font;
  z-index: 2;
  height: 100%;
  overflow: auto;

  .help-page-content {
    clear: both;

    .help-page-closebtn {
      @include fontawesome-icon;
      float: right;
      flex: 0 0 auto;
      margin-left: 5%;
      margin-right: 1%;
      margin-bottom: 3%;
      text-decoration: none;
      text-shadow: -1px -1px rgba(255, 255, 255, 0.44), 1px 1px slategray;
      color: #a7a7a7;
      font-size: 14pt;
      cursor: pointer;
      background-color: transparent;
      border: none;
    }

    .help-page-cards {
      clear: both;

      .card {
        background-color: transparent;

        .card-header {
        padding: 0;

        .help-page-title-text {
          margin-bottom: 0;
          text-decoration: none;
          background-color: $help-color;

          .btn {
            color: white;
            font-weight: bold;
          }

          .btn:hover, .btn:focus, .btn:active {
            outline: none;
            box-shadow: none;
          }
        }
      }

        .list-group-item {
          background-color: transparent;
        }
      }
    }

    .help-page-smaller-italic {
      font-size: smaller;
      font-style: italic;
    }
  }

}

.help-page:before {
  content: ' ';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('images/geologic-clock.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
  opacity: 0.1;
}
