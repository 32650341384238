@import "common.scss";

$max-height: 65vh;
$font-family: firasans;
$font-weight: bold;
$bg-border-color: rgb(0,0,0);
$tile-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
$clicked-shadow: drop-shadow(0px 0px 10px black);
$hover-shadow: drop-shadow(0px 0px 15px orange);

@include media-breakpoint-between(xs, sm) {
  #subject-visual-component-svg {
    font-size: clamp(2rem, calc(max(5vw, 5vh)), 4rem);
  }
}

@include media-breakpoint-between(sm, md) {
  #subject-visual-component-svg {
    font-size: clamp(3rem, calc(max(5vw, 5vh)), 6rem);
  }
}

@include media-breakpoint-between(lg, xl) {
  #subject-visual-component-svg {
    font-size: clamp(4rem, calc(max(5vw, 5vh)), 8rem);
  }
}

.subject-visual-loading {
  display: flex;
  justify-content: center;
  margin-top: 5em;
}

.subject-visual-component {
  position: relative;
  display: flex;
  justify-content: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.5em;

  #subject-visual-legend-btn {
    @include fontawesome-icon;
    position: absolute;
    left: 0.5em;
    top: 0.5em;
    padding-bottom: 0.25em;
    cursor: pointer;
    background-color: transparent;
    border: none;
    box-shadow: $tile-shadow;
  }

  .subject-visual-nocontent {
    font-weight: bold;
    text-align: center;
    border-radius: 1em;
    border: 0.15em solid #000;
    padding-left: 0.5em;
    padding-right: 0.5em;
    margin-top: 5em;
    box-shadow: $tile-shadow;
    background-color: #fffbdc;

    .subject-visual-reset-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: inline;
      margin: 0;
      padding: 0;
      color: blue;
      font-family: $font-family;
    }
  }

  #subject-visual-component-svg {
    font-family: $font-family;
    font-weight: $font-weight;

    .subject-container {
      #subjects {
        rect {
          cursor: pointer;
        }
        rect:hover,
        rect:focus {
          stroke-width: 4;
          stroke: orange;
          filter: $hover-shadow;
          cursor: grab;
        }
      }

      .pageUpBtn, .pageDownBtn {
        @include fontawesome-icon;
        cursor: pointer;
        fill: black;
      }

      .pageBtnHide {
        visibility: hidden;
        opacity: 0;
      }

      .laneBackground {
        fill-opacity: 0.3;
      }

      .subject-outline-clicked {
        stroke-width: 15 !important;
        stroke: black !important;
        filter: $clicked-shadow !important;
        cursor: grabbing;
      }
    }
  }

  .pageBtnTooltip {
    position: absolute;
    opacity: 0.9;
    z-index: 100;
    background-color: white;
    color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 2px;
    box-shadow: $tile-shadow;
  }

  .pageBtnTooltipHide {
    opacity: 0;
  }
}
