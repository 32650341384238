@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@import "common.scss";

$search-bar-color: #ab8dcc;
$tile-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.3);

@include media-breakpoint-between(xs, md) {
  .evo-search {
    .search-results {
      width: 90%;
    }
  }
}

@include media-breakpoint-between(lg, xl) {
  .evo-search {
    .search-results {
      width: 50%;
    }
  }
}

.evo-search {
  float: right;
  margin-left: auto;

  .search-box {
    .search-form {
      flex: 0 1 auto;
      min-width: 6em;
      display: flex;

      .search-term {
        width: unset !important;
        border: 0.2em solid $search-bar-color;
        border-right: none;
        padding: 0.25em;
        border-radius: 0.25em 0 0 0.25em;
        outline: none;
        color: gray;
      }

      .search-term:focus{
        color: $search-bar-color;
      }

      .search-button {
        @include fontawesome-icon;
        width: 2em;
        border: 0.2em solid $search-bar-color;
        background: $search-bar-color;
        text-align: center;
        color: #fff;
        border-radius: 0 0.25em 0.25em 0;
        cursor: pointer;
        font-size: 15pt;
      }
    }
  }

  .search-results-waiting {
    cursor: progress;
  }

  .search-results-show {
    transform: translateX(0%);
    cursor: default;
  }

  .search-results-hide {
    transform: translateX(100%);
    cursor: default;
  }

  .search-results {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transition: .3s ease-out;
    margin-top: 1em;
    margin-bottom: 1.5em;
    background-color: white;
    z-index: 1000;
    box-shadow: $tile-shadow;

    .search-results-inner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
      overflow: auto;

      .search-results-closebtn {
        @include fontawesome-icon;
        float:right;
        margin-top: 0.5em;
        margin-right: 1em;
        text-decoration: none;
        text-shadow: -1px -1px rgba(255, 255, 255, 0.44), 1px 1px slategray;
        color: #a7a7a7;
        font-size: 14pt;
        cursor: pointer;
        background-color: transparent;
        border: none;
      }

      .search-results-content {
        clear: both;
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        padding: 1em;

        [class^="react-pagination-js-"] {
          float: right;
        }

        .search-results-content-items {
          clear: both;
          margin-top: 1em;
          list-style-image: url('images/geologic-clock-icon.png');

          li {
            margin-top: 0.75em;

            .link-button {
              background-color: transparent;
              border: none;
              cursor: pointer;
              display: inline;
              margin: 0;
              padding: 0;
              color: blue;
              font-family: firasans;
            }

            .link-button:hover,
            .link-button:focus {
              text-decoration: underline;
            }
          }
        }

        .search-msg-info {
          color: blue;
          font-weight: bold;
        }

        .search-msg-error {
          color: red;
        }

        .search-results-content-none-found {
          margin-top: 0;
          font-style: italic;
        }
      }
    }
  }
}
