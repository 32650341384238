@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@import "common.scss";

$tile-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.3);

@include media-breakpoint-between(xs, md) {
  #wiki-container {
    max-height: 100vh;

    #wiki-header {
      font-size: 3vw;

      #wiki-header-logo {
        display: none;
      }

      #wiki-closebtn {
        @include fontawesome-icon;
        flex: 0 1 auto;
        text-decoration: none;
        text-shadow: -1px -1px rgba(255, 255, 255, 0.44), 1px 1px slategray;
        color: #a7a7a7;
        font-size: 2vh;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }

    #wiki-main {
      max-height: 55vh;
    }
  }
}

@include media-breakpoint-between(lg, xl) {
  #wiki-container {
    #wiki-header {
      #wiki-closebtn {
        display: none;
      }
    }

    #wiki-main {
      max-height: 6em;
    }
  }
}

#wiki-container {
  display: flex;
  flex-direction: column;
  box-shadow: $tile-shadow;
  font-family: firasans;
  background-color: white;
  padding-left: 0.2em;

  .disappear {
    display: none !important;
    opacity: 0;
  }

  .fade-in {
    opacity: 1;
    transition: opacity 0.5s 0.5s;
  }

  #wiki-header {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-bottom: solid;
    border-color: lightgray;
    border-width: thin;
    margin-top: 0.1em;
    margin-right: 0.25em;
    margin-bottom: 0.2em;
    white-space: nowrap;

    #wiki-header-title {
      flex: 1 1 90vw;
      font-weight: bold;
      margin-top: 0.2em;
      margin-bottom: 0;
      max-width: 90vw;
      width: 90vw;
      overflow-x: auto;
      word-break: normal;
      white-space: normal;
    }

    #wiki-header-logo {
      flex: 0 1 auto;

      img {
        height: 1.5em;
      }
    }
  }

  #wiki-main {
    display: flex;
    flex: 1 0 auto;
    overflow: auto;
    font-size: 2vh;
    margin-bottom: 0.2em;

    #wiki-main-inner {
      margin-bottom: 0.25em;
      width: 100%;

      #wiki-loading {
        display: flex;
        justify-content: center;
        height: 100%;

        .loading {
          margin: auto;
        }
      }

      #wiki-text {
        text-align: justify;
        margin-left: 0.2em;
        margin-right: 0.5em;

        p {
          margin-right: 0.2em;
          margin-block-start: 0;
        }
      }
    }
  }

  #wiki-footer {
    flex: 0 0 0;
    display: flex;
    direction: rtl;
    padding-top: 0.1em;
    border-top: solid;
    border-color: lightgray;
    border-width: thin;
    margin-right: 0.25em;
    font-size: 9pt;

    #unit-defn {
      flex: 1 1 auto;
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      margin-left: 0.5em;
      margin-top: auto;
      margin-bottom: auto;

      span {
        padding-right: 0.5em;
      }
    }

    #link-instruction-arrow {
      flex: 0 1 auto;
      margin-left: 0.2em;
      margin-top: auto;
      margin-bottom: auto;
    }

    #link-instruction-text {
      flex: 0 1 auto;
      text-align: center;
      font-style: italic;
      margin-left: 1em;
      margin-top: auto;
      margin-bottom: auto;
    }

    #wiki-footer-logo {
      flex: 0 1 auto;
      float: right;
      margin-top: auto;
      margin-bottom: auto;

      img {
        height: 2em;
      }
    }
  }
}
