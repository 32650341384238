@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

$max-height: 65vh;
$clicked-shadow: drop-shadow(0px 0px 15px yellow);

@include media-breakpoint-between(xs, sm) {
  #interval-visual-component {
    #interval-visual-component-svg {
      font-size: 2em;
    }
  }
}

@include media-breakpoint-between(sm, md) {
  #interval-visual-component {
    #interval-visual-component-svg {
      font-size: 2.5em;
    }
  }
}

@include media-breakpoint-between(lg, xl) {
  #interval-visual-component {
    #interval-visual-component-svg {
      font-size: 3.5em;
    }
  }
}

.interval-visual-loading {
  display: flex;
  justify-content: center;
  margin-top: 5em;
}

#interval-visual-component {
  max-height: $max-height;
  max-width: $max-height;
  margin-left: auto;
  margin-right: auto;

  #interval-visual-component-svg {
    font-family: firasans;
    max-height: $max-height;
    max-width: $max-height;
    background-color: #ffffff;

    .path-unselected {
      stroke: #4d4d4d;
      stroke-width: 1;
      cursor: grab;
    }

    .path-selected {
      stroke: black;
      stroke-width: 10;
      cursor: grabbing;
      filter: $clicked-shadow !important;
    }

    .path-invisible {
      pointer-events: none;
    }
  }
}
