/* GENERAL */

@-webkit-keyframes fade {
  from { opacity: 1; }
  to { opacity: 0.2; }
}
@keyframes fade {
  from { opacity: 1; }
  to { opacity: 0.2; }
}

@-webkit-keyframes rotate {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


/* WIND CATCHER */

@-webkit-keyframes windcatcherSpin {
  from { -webkit-transform: rotateY(0deg) rotateX(-20deg); }
  to { -webkit-transform: rotateY(360deg) rotateX(-20deg); }
}
@keyframes windcatcherSpin {
  from { transform: rotateY(0deg) rotateX(-20deg); }
  to { transform: rotateY(360deg) rotateX(-20deg); }
}

@-webkit-keyframes windcatcherBg {
  0% { background-color: rgb(255, 0, 0); }
  50% { background-color: rgb(150, 0, 0); }
  51% { background-color: rgb(255, 100, 100); }
  70% { background-color: rgb(255, 25, 25); }
  100% { background-color: rgb(255, 0, 0); }
}
@keyframes windcatcherBg {
  0% { background-color: rgb(255, 0, 0); }
  50% { background-color: rgb(150, 0, 0); }
  51% { background-color: rgb(255, 100, 100); }
  70% { background-color: rgb(255, 25, 25); }
  100% { background-color: rgb(255, 0, 0); }
}

.spinner.windcatcher {
  width: 4em;
  perspective: 50em;
  -webkit-animation: rotate 4s linear infinite;
  animation: rotate 4s linear infinite;
}

.spinner.windcatcher .blade {
  height: 0.5em;
  background: red;
  margin-bottom: 0.1em;
  -webkit-animation: windcatcherSpin 4s linear infinite, windcatcherBg 2s linear infinite;
  animation: windcatcherSpin 4s linear infinite, windcatcherBg 2s linear infinite;
}

.spinner.windcatcher .blade:nth-child(1) { -webkit-animation-delay: 0s; animation-delay: 0s; }
.spinner.windcatcher .blade:nth-child(2) { -webkit-animation-delay: 0.25s; animation-delay: 0.25s; }
.spinner.windcatcher .blade:nth-child(3) { -webkit-animation-delay: 0.5s; animation-delay: 0.5s; }
.spinner.windcatcher .blade:nth-child(4) { -webkit-animation-delay: 0.75s; animation-delay: 0.75s; }
.spinner.windcatcher .blade:nth-child(5) { -webkit-animation-delay: 1s; animation-delay: 1s; }
.spinner.windcatcher .blade:nth-child(6) { -webkit-animation-delay: 1.25s; animation-delay: 1.25s; }
.spinner.windcatcher .blade:nth-child(7) { -webkit-animation-delay: 1.5s; animation-delay: 1.5s; }
.spinner.windcatcher .blade:nth-child(8) { -webkit-animation-delay: 1.75s; animation-delay: 1.75s; }


/* SLABS */

@-webkit-keyframes slabMove {
  0% { -webkit-transform: translateY(0) rotateX(30deg); opacity: 0; }
  10% { -webkit-transform: translateY(-48%); opacity: 1; }
  90% { -webkit-transform: translateY(-422%); opacity: 0.1; }
  100% { -webkit-transform: translateY(-480%); opacity: 0; }
}
@keyframes slabMove {
  0% { transform: translateY(0) rotateX(30deg); opacity: 0; }
  10% { transform: translateY(-48%); opacity: 1; }
  90% { transform: translateY(-422%); opacity: 0.1; }
  100% { transform: translateY(-480%); opacity: 0; }
}

.spinner.slabs {
  width: 4em;
  height: 4em;
  -webkit-transform: perspective(15em) rotateX(65deg) rotateZ(-30deg);
  transform: perspective(15em) rotateX(65deg) rotateZ(-30deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.spinner.slabs .slab {
  position: absolute;
  width: 4em;
  height: 4em;
  background: gray;
  opacity: 0;
  box-shadow: -0.08em 0.15em 0 rgb(55, 55, 55);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: slabMove 4s linear infinite;
  animation: slabMove 4s linear infinite;
}

.spinner.slabs .slab:nth-child(1) { -webkit-animation-delay: 0s; animation-delay: 0s; }
.spinner.slabs .slab:nth-child(2) { -webkit-animation-delay: 1s; animation-delay: 1s; }
.spinner.slabs .slab:nth-child(3) { -webkit-animation-delay: 2s; animation-delay: 2s; }
.spinner.slabs .slab:nth-child(4) { -webkit-animation-delay: 3s; animation-delay: 3s; }


/* NEWTON'S BALLS */

@-webkit-keyframes newtonBallFirst {
  0% { -webkit-transform: rotate(70deg); }
  50% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(0deg); }
}
@-webkit-keyframes newtonBallMiddle {
  0% { -webkit-transform: rotate(0deg); }
  50% { -webkit-transform: rotate(0deg); }
  51% { -webkit-transform: rotate(-0.5deg); }
  52% { -webkit-transform: rotate(0.5deg); }
  53% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(0deg); }
}
@-webkit-keyframes newtonBallLast {
  0% { -webkit-transform: rotate(0deg); }
  50% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(-70deg); }
}
@keyframes newtonBallFirst {
  0% { transform: rotate(70deg); }
  50% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}
@keyframes newtonBallMiddle {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(0deg); }
  51% { transform: rotate(-0.5deg); }
  52% { transform: rotate(0.5deg); }
  53% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}
@keyframes newtonBallLast {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(0deg); }
  100% { transform: rotate(-70deg); }
}

.spinner.newton .ball {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 6em;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.spinner.newton .ball::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  background: radial-gradient(circle at 40% 40%, rgb(79, 77, 77) 39%, rgb(55, 55, 55) 42%);
}

.spinner.newton .ball::before {
  content: '';
  position: absolute;
  width: 0.08em;
  margin-left: -0.04em;
  top: 0;
  left: 50%;
  bottom: 1em;
  background: linear-gradient(transparent, gray);
}

.spinner.newton .ball {
  -webkit-animation: newtonBallMiddle 1s infinite alternate;
  animation: newtonBallMiddle 1s infinite alternate;
}

.spinner.newton .ball:first-child {
  -webkit-animation: newtonBallFirst 1s ease-in infinite alternate;
  animation: newtonBallFirst 1s ease-in infinite alternate;
}
.spinner.newton .ball:first-child::after {
  -webkit-animation: newtonBallLast 1s ease-out infinite alternate-reverse;
  animation: newtonBallLast 1s ease-out infinite alternate-reverse;
}

.spinner.newton .ball:last-child {
  -webkit-animation: newtonBallLast 1s ease-out infinite alternate;
  animation: newtonBallLast 1s ease-out infinite alternate;
}
.spinner.newton .ball:last-child::after {
  -webkit-animation: newtonBallFirst 1s ease-in infinite alternate-reverse;
  animation: newtonBallFirst 1s ease-in infinite alternate-reverse;
}


/* CLOCK */

@-webkit-keyframes clockHandRotate {
  from { -webkit-transform: rotate(0deg) translateY(6%); }
  to { -webkit-transform: rotate(360deg) translateY(6%); }
}
@keyframes clockHandRotate {
  from { transform: rotate(0deg) translateY(6%); }
  to { transform: rotate(360deg) translateY(6%); }
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0, 0, 0);
  }
  40% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -2em, 0);
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -1em, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -0.25em, 0);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transform: translate3d(0, 0, 0);
  }
  40% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -2em, 0);
  }
  70% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -1em, 0);
  }
  90% {
    transform: translate3d(0, -0.25em, 0);
  }
}

@-webkit-keyframes clockShadowFade {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    opacity: 1;
  }
  40% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    opacity: 0.2;
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    opacity: 0.4;
  }
  90% {
    opacity: 0.8;
  }
}
@keyframes clockShadowFade {
  0%, 20%, 50%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    opacity: 1;
  }
  40% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    opacity: 0.2;
  }
  70% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    opacity: 0.4;
  }
  90% {
    opacity: 0.8;
  }
}

.spinner.clock {
  width: 4em;
  height: 4em;
  position: relative;
}

.spinner.clock .dial {
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, white, rgb(210, 210, 210));
  border: 0.2em solid #333;
  border-radius: 100%;
  box-sizing: border-box;
  -webkit-animation: bounce 1.5s infinite;
  animation: bounce 1.5s infinite;
}

.spinner.clock .dial .hand {
  position: absolute;
  bottom: 2em;
  width: 0.2em;
  left: 50%;
  margin-left: -0.1em;
  background-color: #333;
  border-radius: 0 0 0.2em 0.2em;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.spinner.clock .dial .hour.hand {
  height: 1em;
  -webkit-animation: clockHandRotate 12s linear infinite;
  animation: clockHandRotate 12s linear infinite;
}

.spinner.clock .dial .minute.hand {
  height: 1.5em;
  -webkit-animation: clockHandRotate 1s linear infinite;
  animation: clockHandRotate 1s linear infinite;
}

.spinner.clock .shadow {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.5em;
  margin-bottom: -0.25em;
  background: radial-gradient(rgba(0, 0, 0, 0.4), transparent 60%);
  -webkit-animation: clockShadowFade 1.5s linear infinite;
  animation: clockShadowFade 1.5s linear infinite;
}


/* COLOR SPHERE */

@-webkit-keyframes sphereSpin {
  0% { -webkit-transform: rotateX(360deg) rotateY(0deg); }
  100% { -webkit-transform: rotateX(0deg) rotateY(360deg); }
}
@keyframes sphereSpin {
  0% { transform: rotateX(360deg) rotateY(0deg); }
  100% { transform: rotateX(0deg) rotateY(360deg); }
}

.spinner.sphere {
  width: 4em;
  height: 4em;
  -webkit-transform: perspective(20em) rotateX(-24deg) rotateY(20deg) rotateZ(30deg);
  transform: perspective(20em) rotateX(-24deg) rotateY(20deg) rotateZ(30deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.spinner .inner {
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-animation: sphereSpin 6s linear infinite;
  animation: sphereSpin 6s linear infinite;
}

.spinner.sphere .disc {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 0.3em dotted black;
}

@-webkit-keyframes rotateDisc2 {
  from { -webkit-transform: rotateX(90deg) rotateZ(0deg); }
  to { -webkit-transform: rotateX(90deg) rotateZ(360deg); }
}
@keyframes rotateDisc2 {
  from { transform: rotateX(90deg) rotateZ(0deg); }
  to { transform: rotateX(90deg) rotateZ(360deg); }
}

@-webkit-keyframes rotateDisc3 {
  from { -webkit-transform: rotateY(90deg) rotateZ(0deg); }
  to { -webkit-transform: rotateY(90deg) rotateZ(360deg); }
}
@keyframes rotateDisc3 {
  from { transform: rotateY(90deg) rotateZ(0deg); }
  to { transform: rotateY(90deg) rotateZ(360deg); }
}

.spinner.sphere .disc:nth-child(1) {
  -webkit-animation: rotate 12s linear infinite;
  animation: rotate 12s linear infinite;
}
.spinner.sphere .disc:nth-child(2) {
  -webkit-animation: rotateDisc2 12s linear infinite;
  animation: rotateDisc2 12s linear infinite;
}
.spinner.sphere .disc:nth-child(3) {
  -webkit-animation: rotateDisc3 12s linear infinite;
  animation: rotateDisc3 12s linear infinite;
}


/* COLOR WHEEL */

.spinner.colorwheel {
  position: relative;
  display: inline-block;
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 100%;
  z-index: 0;
}

.spinner.colorwheel::before,
.spinner.colorwheel::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spinner.colorwheel::before {
  background: linear-gradient(to right, green, yellow);
  -webkit-animation: rotate 2.5s linear infinite;
  animation: rotate 2.5s linear infinite;
}

.spinner.colorwheel::after {
  background: linear-gradient(to bottom, red, blue);
  -webkit-animation: fade 2s infinite alternate, rotate 2.5s linear reverse infinite;
  animation: fade 2s infinite alternate, rotate 2.5s linear reverse infinite;
}

.spinner .centerpiece {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 100%;
  box-sizing: border-box;
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
  border-bottom: 0.5em solid rgba(255, 255, 255, 0.3);
  border-top: 0.5em solid rgba(255, 255, 255, 0.3);
  -webkit-animation: rotate 0.8s linear infinite;
  animation: rotate 0.8s linear infinite;
}


/* INFINITY */

.spinner.infinity {
  -webkit-transform: perspective(10em) rotateZ(90deg) rotateY(30deg);
  transform: perspective(10em) rotateZ(90deg) rotateY(30deg);
}

.spinner.infinity .half {
  position: relative;
  width: 4em;
  height: 4em;
}

.spinner.infinity .marker {
  position: absolute;
  width: 100%;
  height: 100%;
}

@-webkit-keyframes rotateHide {
  0% { -webkit-transform: rotate(0deg); opacity: 0; }
  25% { opacity: 1; }
  50%, 100% { -webkit-transform: rotate(360deg); opacity: 0; }
}
@keyframes rotateHide {
  0% { transform: rotate(0deg); opacity: 0; }
  25% { opacity: 1; }
  50%, 100% { transform: rotate(360deg); opacity: 0; }
}

.spinner.infinity .marker::after {
  opacity: 0;
  content: '\2022';
  width: 100%;
  height: 100%;
  display: block;
  -webkit-animation: rotateHide 3.5s cubic-bezier(0.4,0.1,0.6,0.9) infinite;
  animation: rotateHide 3.5s cubic-bezier(0.4,0.1,0.6,0.9) infinite;
}

.spinner.infinity .half:first-child {
  -webkit-transform: translateY(1em) rotateX(180deg);
  transform: translateY(1em) rotateX(180deg);
}

.spinner.infinity .half:first-child .marker:nth-child(1)::after { -webkit-animation-delay: 0s; animation-delay: 0s; }
.spinner.infinity .half:first-child .marker:nth-child(2)::after { -webkit-animation-delay: 0.25s; animation-delay: 0.25s; }
.spinner.infinity .half:first-child .marker:nth-child(3)::after { -webkit-animation-delay: 0.5s; animation-delay: 0.5s; }
.spinner.infinity .half:first-child .marker:nth-child(4)::after { -webkit-animation-delay: 0.75s; animation-delay: 0.75s; }
.spinner.infinity .half:first-child .marker:nth-child(5)::after { -webkit-animation-delay: 1s; animation-delay: 1s; }
.spinner.infinity .half:first-child .marker:nth-child(6)::after { -webkit-animation-delay: 1.25s; animation-delay: 1.25s; }
.spinner.infinity .half:first-child .marker:nth-child(7)::after { -webkit-animation-delay: 1.5s; animation-delay: 1.5s; }
.spinner.infinity .half:first-child .marker:nth-child(8)::after { -webkit-animation-delay: 1.75s; animation-delay: 1.75s; }

.spinner.infinity .half:last-child .marker:nth-child(1)::after { -webkit-animation-delay: 1.75s; animation-delay: 1.75s; }
.spinner.infinity .half:last-child .marker:nth-child(2)::after { -webkit-animation-delay: 2s; animation-delay: 2s; }
.spinner.infinity .half:last-child .marker:nth-child(3)::after { -webkit-animation-delay: 2.25s; animation-delay: 2.25s; }
.spinner.infinity .half:last-child .marker:nth-child(4)::after { -webkit-animation-delay: 2.5s; animation-delay: 2.5s; }
.spinner.infinity .half:last-child .marker:nth-child(5)::after { -webkit-animation-delay: 2.75s; animation-delay: 2.75s; }
.spinner.infinity .half:last-child .marker:nth-child(6)::after { -webkit-animation-delay: 3s; animation-delay: 3s; }
.spinner.infinity .half:last-child .marker:nth-child(7)::after { -webkit-animation-delay: 3.25s; animation-delay: 3.25s; }
.spinner.infinity .half:last-child .marker:nth-child(8)::after { -webkit-animation-delay: 3.5s; animation-delay: 3.5s; }
