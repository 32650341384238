@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@import "common.scss";

$max-height: 65vh;
$font: bold 12px firasans;
$bg-border-color: #2e035e;
$tile-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
$transition-show: max-width 0.3s linear, opacity 0.1s linear, color 0.3s linear 0.1s;
$transition-hide: color 0.3s linear, opacity 0.1s linear 0.1s, max-width 0.3s linear 0.1s;

@include media-breakpoint-between(xs, md) {

  #subject-visual-legend.show {
    max-width: 70%;
    min-width: 40%;
  }
}

@include media-breakpoint-between(lg, xl) {

  #subject-visual-legend.show {
    max-width: 30%;
    min-width: 30%;
  }
}

#subject-visual-legend {
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  height: 100%;
  z-index: 1000;

  .subject-visual-legend-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .subject-visual-legend-title-row {
      flex: 0 0 0;
      margin-top: 0.2em;
      margin-bottom: 0.2em;

      .subject-visual-legend-closebtn {
        @include fontawesome-icon;
        float:right;
        margin-right: 0.5em;
        text-decoration: none;
        text-shadow: -1px -1px rgba(255, 255, 255, 0.44), 1px 1px slategray;
        color: #a7a7a7;
        font-size: 14pt;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }

    .subject-visual-legend-text {
      flex: 0 0 auto;
      margin-left: 0.5em;
      margin-right: 0.5em;
      font-size: x-small;
      font-style: italic;
    }

    .subject-visual-legend-kinds {
      flex: 1 1 1px;
      width: 100%;

      .tabs {
        height: calc(100% - 0.5em);

        .tab-list {
          font-size: small;
        }
        .tab-content {
          display: flex;
          flex-direction: column;
          padding-top: 1.5em;

          .subject-visual-legend-items {
            flex: 1 1 1px;
            overflow: auto;

            .subject-legend-content-none-found {
              text-align: center;
              width: 100%;
              font-weight: bold;
              font-style: italic;
            }

            .subject-visual-legend-items-inner {
              clear: both;
              margin-left: auto;
              margin-right: auto;

              li {
                list-style-type: none;

                div {
                  display: inline-block;
                  position: relative;
                  cursor: pointer;

                  svg {
                    display: inline-block;
                    vertical-align: middle;
                    margin-bottom: .2em;
                    width: 2em;
                    height: 2em;

                    rect {
                      transition: fill .4s linear;
                    }
                  }
                }
              }
            }
          }

          .subject-visual-legend-footer {
            flex: 0 0 auto;
            height: 1em;
            max-height: 1em;
            width: 100%;
            background-image: linear-gradient(to top, #ab8dcc 0.2em, white 0.8em, white);
          }
        }
      }
    }
  }
}

#subject-visual-legend.hide {
  max-width: 0;
  opacity: 0;
  color: white;
  box-shadow: none;
  webkit-transition: $transition-hide;
  -moz-transition: $transition-hide;
  -ms-transition: $transition-hide;
  -o-transition: $transition-hide;
  transition: $transition-hide;
}

#subject-visual-legend.show {
  opacity: 1;
  color: black;
  box-shadow: $tile-shadow;
  webkit-transition: $transition-show;
  -moz-transition: $transition-show;
  -ms-transition: $transition-show;
  -o-transition: $transition-show;
  transition: $transition-show;
}
